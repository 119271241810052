import React from 'react'

const NUMBER_OF_HOLES = 11 // includes two half circles

// Grocery List Image
function GroceriesSVG(props) {

  return (<GroceryListV2 {...props}/>)
}

// Simple grocery list design
function GroceryListV1(props) {
  
  return (
    <svg className='GroceryList' onClick={props.onClick} viewBox="0 0 500 650" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="100%" fill="#F1E8D1"/>
      {[...props.imageList].map((element, i) => {
        return(
          <image key={i} x="40" y={50 * i} width="400" height="100" xlinkHref={element}/>
        )})
      }
      {[...Array(props.maxItems).keys()].map((element, i) => {
        return(
          <line key={i} x1="10" y1={50 * (i + 1)} x2="490" y2={50 * (i + 1)} stroke="#806127"/>
        )})
      }   
    </svg>
  )
}

// Fancy grocery list design
function GroceryListV2(props) {

  return (
    <svg className='GroceryList' onClick={props.onClick} viewBox="0 0 500 750" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="100%" fill="#F1E8D1"/>
      <line opacity="0.80" x1="70" y1="0" x2="70" y2="750" stroke="#E62828"/>
      <line opacity="0.80" x1="70" y1="70" x2="500" y2="70" stroke="#E62828"/>
      {[...Array(NUMBER_OF_HOLES).keys()].map((element, i) => {
        return(
          <circle key={i} cx="35" cy={70 * (i)} r="20" opacity="0.80" fill="#FFFDFD" />
        )})
      }  
      {[...props.imageList].map((element, i) => {
        return(
          <image key={i} x="60" y={70 + 50 * i} width="400" height="100" xlinkHref={element}/>
        )})
      }
      {[...Array(props.maxItems).keys()].map((element, i) => {
        return(
          <line key={i} opacity="0.60" x1="70" y1={70 + 50 * (i + 1)} x2="490" y2={70 + 50 * (i + 1)} stroke="black"/>
        )})
      }
      <rect y="-50" width="100%" height="50" fill="#F4EEA9"/> {/* this is a cheat, change color according to background! */}
    </svg>
  )
}

export default GroceriesSVG;