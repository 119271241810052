import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import HomeRoute from './routes/HomeRoute'
import AdminRoute from './routes/AdminRoute'

/**
 * The application routes.
 * Shows different layouts and pages based on the current route.
 */
function AppRoutes() {

  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<HomeLayout><HomeRoute /></HomeLayout>} />
      <Route path="admin" element={<AdminLayout><AdminRoute /></AdminLayout>} />
      </Routes>
    </BrowserRouter>
  )
}

// Layout of the home page.
function HomeLayout(props) {

  return (
    <div className='MainContainer'>
      {props.children}
    </div>
  )
}

// Layout of the admin page.
function AdminLayout(props) {

  return (
    <div className='MainContainer'>
      {props.children}
    </div>
  )
}

export default AppRoutes;