import React, { useState, useEffect } from 'react';

import { storage } from '../services/firebase.js'
import { ref, listAll, getBlob } from 'firebase/storage';

import GroceriesSVG from '../components/GroceriesSVG'
import useAudio from '../hooks/useAudio.jsx';
import logo from '../assets/logo.svg'; 
import blender from '../assets/blender.mp3'
import ping from '../assets/ping.mp3'

const MIN_NUMBER_OF_INGREDIENTS = 6
const MAX_NUMBER_OF_INGREDIENTS = 12

/// Get a random number ata given interval
function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/// Home route component
function HomeRoute() {
  let [landing, setLanding] = useState(true)
  let [loading, setLoading] = useState(true)
  let [imageList, setImageList] = useState("")
  const [playBlender, stopBlender] = useAudio(blender);
  const [playPing, stopPing] = useAudio(ping);

  // Function to read the file asynchronously
  function readFileAsDataURL(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onloadend = () => resolve(reader.result)  
      reader.onerror = reject;
      reader.readAsDataURL(file);
    })
  }

  // Function to get a random collection of ingredients
  async function getImages() {
    try {
      let imageData = new Set()
      // Get the storage reference
      const randomAmount = randomIntFromInterval(MIN_NUMBER_OF_INGREDIENTS, MAX_NUMBER_OF_INGREDIENTS)
      const filesRef = ref(storage, '/snippets');
      const filesList = await listAll(filesRef)
      while (imageData.size < randomAmount) {
        // Get the image
        const randomIndex = randomIntFromInterval(0, filesList.items.length - 1)
        const imageRef = filesList.items[randomIndex]
        const imageBlob = await getBlob(imageRef)
        // Convert to data url
        const imageDataURL = await readFileAsDataURL(imageBlob)
        imageData.add(imageDataURL)
        console.log("Image loaded: " + imageRef)
      }
      setImageList(imageData)
      setLoading(false)
    } catch(e) {
      console.log(e);
    }
  }

  // Side effects
  useEffect(() => {
    // Landing effects
    if (landing) {
      console.log("Boodschappenblender!")
    }
    // Loading effects
    else if (loading) {
      getImages()
      stopPing()
      playBlender()
    }
    // List effects
    else {
      stopBlender()
      playPing()
    }
  }, [landing, loading]);

  // Landing view
  if (landing) {
    return (
      <div className="HomeGreen">
        <img src={logo} className="LogoStatic" alt="logo" onClick={e => {setLanding(false)}} />
      </div>
    )
  }

  // Loading view
  else if (loading) {
    return (
      <div className="HomeGreen">
        <img src={logo} className="LogoLoading" alt="logo" onClick={e => {setLoading(false)}} />
      </div>
    )
  }

  // List view
  else {
    return (
      <div className="HomeList">
        <GroceriesSVG onClick={e => {setLoading(true)}} imageList={imageList} minItems={MIN_NUMBER_OF_INGREDIENTS} maxItems={MAX_NUMBER_OF_INGREDIENTS} />
      </div>
    )
  }

}
  
export default HomeRoute;