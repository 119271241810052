import React from 'react';

function AdminRoute() {

  // Select the file when the form changes
  // const selectFile = (e) => {
  //   console.log("Selecting a file...");
  //   console.log(e.target.files[0])
  // }

  return (
    <p>Admin</p>
  )
}

export default AdminRoute;