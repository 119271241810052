import React, { useState, useEffect } from 'react';

// Use audio hook
function useAudio(url) {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const play = () => { setPlaying(true); audio.currentTime = 0; }
  const stop = () => { setPlaying(false); audio.currentTime = 0; }

  useEffect(() => {
      playing ? audio.play() : audio.pause();
    },
    [playing]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [play, stop];
};

export default useAudio;